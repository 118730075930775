<template>
  <div class="px-5">
    <div class="top">
      <div class="font-weight-bold text-h5 mb-4">Kiểm kho</div>
      <div class="d_flex align-center justify-space-between">
        <div>
          <v-autocomplete
            class="text-body-1 rounded-lg mr-8"
            clearable
            dense
            flat
            :items="branches"
            item-text="name"
            item-value="id"
            hide-details
            no-data-text="Không có dữ liệu"
            placeholder="Chọn kho kiểm"
            return-object
            solo
            v-model="branch"
            :disabled="rightCount > 0 || wrongCount > 0 || page === 'detail'"
          ></v-autocomplete>
        </div>
        <div v-if="page === 'add'">
          <v-btn
            class="rounded-lg mr-4"
            color="white"
            depressed
            @click="cancelAll"
          >
            Hủy
          </v-btn>
          <v-btn class="rounded-lg" color="primary" depressed @click="complete">
            Hoàn thành
          </v-btn>
        </div>
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="showModalAddRoleServer"
          v-if="inventory.id"
        >
          <v-icon left>mdi-plus</v-icon> Thêm phục vụ
        </v-btn>
      </div>
    </div>

    <v-container fluid class="pa-0">
      <div class="d_flex align-center justify-center mt-5 mb-8">
        <div class="search-box">
          <v-text-field
            class="rounded-lg text-body-1"
            clearable
            flat
            hide-details
            placeholder="Nhập serial cần kiểm"
            prepend-inner-icon="mdi-magnify"
            solo
            @keydown.enter="openModalCheckingSerial"
            :disabled="page === 'detail'"
          ></v-text-field>
        </div>
      </div>
      <v-row class="white rounded-lg mx-0 pa-3">
        <v-col
          class="fixed-height"
          cols="12"
          v-if="
            inventoryRequestStatus.value &&
              inventoryRequestStatus.value.slice(0, 7) === 'loading'
          "
        >
          <v-progress-linear
            color="light-blue"
            buffer-value="0"
            stream
          ></v-progress-linear>
        </v-col>
        <template v-else>
          <v-col class="fixed-height" cols="4">
            <div class="text-h6 font-weight-bold text-center mb-4">
              Chờ kiểm ({{ waitingCount }}/{{ total }})
            </div>
            <div class="scrollable">
              <v-tooltip
                bottom
                v-for="(item, index) in inventory.waiting"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="font-weight-medium serial-item mr-3 my-1"
                    color="grey lighten-4"
                    label
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToClipboard(item.serial_number)"
                  >
                    {{ item.serial_number }}
                  </v-chip>
                </template>
                <span>Sao chép</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col class="fixed-height" cols="4">
            <div class="text-h6 font-weight-bold text-center mb-4">
              Đúng thông tin ({{ rightCount }}/{{ total }})
            </div>
            <div class="scrollable">
              <v-tooltip
                bottom
                v-for="(item, index) in inventory.right"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="font-weight-medium serial-item mr-3 my-1"
                    color="green lighten-4"
                    label
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToClipboard(item.serial_number)"
                  >
                    {{ item.serial_number }}
                  </v-chip>
                </template>
                <span>Sao chép</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col class="fixed-height" cols="4">
            <div class="text-h6 font-weight-bold text-center mb-4">
              Sai thông tin ({{ wrongCount }}/{{ total }})
            </div>
            <div class="scrollable">
              <v-tooltip
                bottom
                v-for="(item, index) in inventory.wrong"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="font-weight-medium serial-item mr-3 my-1"
                    color="red lighten-3"
                    label
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToClipboard(item.serial_number)"
                  >
                    {{ item.serial_number }}
                  </v-chip>
                </template>
                <span>Sao chép</span>
              </v-tooltip>
            </div>
          </v-col>
        </template>
      </v-row>
      <v-row
        class="white rounded-lg mx-0 pa-3 mt-4"
        v-if="inventory && inventory.participants"
      >
        <div
          v-for="(participant, index) in inventory.participants"
          :key="`participant-${index}`"
        >
          <v-divider class="my-3"></v-divider>
          <div>
            <div class="font-weight-bold d-flex align-center mb-2">
              <span>{{ getNameRole(participant.role) }}</span>
            </div>
            <v-autocomplete
              v-model="participant.user_id"
              class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
              dense
              flat
              hide-details
              :items="employees"
              item-text="name"
              item-value="id"
              no-data-text="Không có dữ liệu"
              placeholder="Kỹ thuật viên"
              disabled
            ></v-autocomplete>
            <v-text-field
              v-if="participant.role === 'SHIPPING'"
              v-model="participant.value"
              class="text-body-1 mt-4"
              clearable
              dense
              hide-details="auto"
              outlined
              placeholder="Quãng đường (km)"
              disabled
            ></v-text-field>
          </div>
          <div class="mt-4">
            <v-textarea
              v-model="participant.note"
              class="text-body-1 mt-2"
              auto-grow
              hide-details
              outlined
              rows="3"
              single-line
              placeholder="Ghi chú"
              disabled
            ></v-textarea>
          </div>
        </div>
      </v-row>
    </v-container>

    <!-- Start: Modal checking serial -->
    <modal-checking-serial v-if="page === 'add'"></modal-checking-serial>
    <!-- End: Modal checking serial -->
  </div>
</template>

<script>
import ModalCheckingSerial from "./components/ModalCheckingSerial";

export default {
  components: {
    ModalCheckingSerial
  },
  data() {
    return {
      branch: {},
      searchOptions: [{ type: "search", label: "Nhập serial cần kiểm" }],
      page: "add"
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    roles() {
      return this.$store.getters["ROLE/rolesName"];
    },
    inventory() {
      return this.$store.getters["INVENTORY/inventory"];
    },
    waitingCount() {
      return this.inventory.waiting.length;
    },
    rightCount() {
      return this.inventory.right.length;
    },
    wrongCount() {
      return this.inventory.wrong.length;
    },
    total() {
      return this.waitingCount + this.rightCount + this.wrongCount;
    },
    inventoryRequestStatus() {
      return this.$store.getters["INVENTORY/statusRequest"];
    }
  },
  watch: {
    "branch.id"(newId) {
      if (this.page === "add") {
        this.$store.dispatch("INVENTORY/getInventory", {
          inventoryId: 0,
          branchId: newId
        });
      }
    }
  },
  async created() {
    await this.$store.dispatch("INVENTORY/removeAll");
    const inventoryId = this.$route.params.inventoryId;
    if (inventoryId) {
      await this.$store.dispatch("INVENTORY/getInventory", {
        inventoryId: inventoryId,
        branchId: this.branch.id
      });
      const branchId = this.inventory.branchId;
      this.branch = this.branches.find(e => e.id === branchId);
      this.page = "detail";
    }

    if (this.branches.length === 0) {
      await this.$store.dispatch("BRANCH/getAllBranches");
    }
  },
  methods: {
    async complete() {
      await this.$store.dispatch("INVENTORY/completeInventory");
      if (this.inventoryRequestStatus.value === "success-completeInventory") {
        await this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Hoàn thành kiểm kho!"
          }
        });
        this.$router.push({ name: "goods_inventory" });
      }
    },
    cancelAll() {
      this.branch = {};
      this.$store.dispatch("INVENTORY/removeAll");
    },
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },
    getNameRole(role) {
      return this.roles.filter(item => item.value === role)[0]["text"];
    },
    showModalAddRoleServer() {
      const inventoryId = this.$route.params.inventoryId;
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setBranchIDModelAddRoleServer",
        this.branch && this.branch.id ? this.branch.id : null
      );
      this.$modal.show({
        name: "modal-add-role-server",
        payload: {
          model_id:
            this.inventory && this.inventory.id ? this.inventory.id : null,
          model_name: "App\\Models\\OffBiz",
          dataNamespaced: "INVENTORY/getInventory",
          dataRequest: {
            inventoryId: inventoryId,
            branchId: this.branch && this.branch.id ? this.branch.id : null
          }
        }
      });
    },

    async openModalCheckingSerial(e) {
      const val = e.target.value;
      const inWaiting = this.inventory.waiting.find(
        i => i.serial_number === val
      );
      const inRight = this.inventory.right.find(i => i.serial_number === val);
      const inWrong = this.inventory.wrong.find(i => i.serial_number === val);
      if (inRight || inWrong) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: "Thông báo!",
            message: "Đã kiểm!"
          }
        });
      } else if (!inWaiting) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: "Thông báo!",
            message: "Không có trong danh sách chờ!"
          }
        });
      } else {
        await this.$store.dispatch("INVENTORY/getSerialInfo", {
          serialNumber: e.target.value
        });
        this.$modal.show({ name: "modal-checking-serial" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-box {
  max-width: 500px;
  min-width: 360px;
  width: 40%;
}

.fixed-height {
  max-height: calc(100vh - 64px - 53px - 82px - 92px - 40px - 24px);
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 82px - 92px - 40px - 24px);
  }
}
.scrollable {
  max-height: calc(100% - 28px - 16px);
  overflow: scroll;
}

.serial-item {
  cursor: pointer;
}
</style>
