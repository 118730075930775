<template>
  <tp-modal name="modal-checking-serial" max-width="768px" width="40%">
    <v-card flat>
      <v-card-title>
        <div class="font-weight-bold">
          Kiểm tra thông tin serial #{{ serialInfo.serial_number }}
        </div>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="pa-5" flat>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Mã sản phẩm:</span>
            {{ serialInfo.product_code }}
          </div>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Tên sản phẩm:</span>
            {{ serialInfo.product_name }}
          </div>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Mã SKU:</span>
            {{ serialInfo.option_sku }}
          </div>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Tên SKU:</span>
            <span v-html="serialInfo.option_name"></span>
          </div>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Giá bán:</span>
            {{ serialInfo.price | formatCurrency }}
          </div>
          <v-divider class="my-3"></v-divider>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Phiếu nhập:</span>
            {{ serialInfo.hnd_code }}
          </div>
          <div class="text-body-1  mb-1">
            <span class="font-weight-bold">Giá bán:</span>
            {{ serialInfo.created_price | formatCurrency }}
          </div>
          <div class="text-body-1 mb-1">
            <span class="font-weight-bold">Ngày nhập:</span>
            {{ serialInfo.created_at }}
          </div>
        </v-card>
      </v-container>

      <v-card-actions class="px-5 py-4 justify-space-between">
        <v-btn
          class="green lighten-5 px-4 mx-5"
          color="green"
          text
          @click="checkRight"
        >
          <v-icon left>mdi-check-outline</v-icon>
          Đúng
        </v-btn>
        <v-btn
          class="red lighten-5 px-4 mx-5"
          color="red accent-2"
          text
          @click="checkWrong"
        >
          <v-icon left>mdi-close-outline</v-icon>
          Sai
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-checking-serial" });
    },
    async checkRight() {
      await this.$store.dispatch("INVENTORY/checkRight", {
        id: this.serialInfo.id,
        serial_number: this.serialInfo.serial_number
      });
      this.$modal.hide({ name: "modal-checking-serial" });
    },
    async checkWrong() {
      await this.$store.dispatch("INVENTORY/checkWrong", {
        id: this.serialInfo.id,
        serial_number: this.serialInfo.serial_number
      });
      this.$modal.hide({ name: "modal-checking-serial" });
    }
  },
  computed: {
    serialInfo() {
      return this.$store.getters["INVENTORY/serialInfo"];
    }
  }
};
</script>

<style lang="scss" scoped></style>
