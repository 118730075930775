var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"font-weight-bold text-h5 mb-4"},[_vm._v("Kiểm kho")]),_c('div',{staticClass:"d_flex align-center justify-space-between"},[_c('div',[_c('v-autocomplete',{staticClass:"text-body-1 rounded-lg mr-8",attrs:{"clearable":"","dense":"","flat":"","items":_vm.branches,"item-text":"name","item-value":"id","hide-details":"","no-data-text":"Không có dữ liệu","placeholder":"Chọn kho kiểm","return-object":"","solo":"","disabled":_vm.rightCount > 0 || _vm.wrongCount > 0 || _vm.page === 'detail'},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),(_vm.page === 'add')?_c('div',[_c('v-btn',{staticClass:"rounded-lg mr-4",attrs:{"color":"white","depressed":""},on:{"click":_vm.cancelAll}},[_vm._v(" Hủy ")]),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","depressed":""},on:{"click":_vm.complete}},[_vm._v(" Hoàn thành ")])],1):_vm._e(),(_vm.inventory.id)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","depressed":""},on:{"click":_vm.showModalAddRoleServer}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm phục vụ ")],1):_vm._e()],1)]),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d_flex align-center justify-center mt-5 mb-8"},[_c('div',{staticClass:"search-box"},[_c('v-text-field',{staticClass:"rounded-lg text-body-1",attrs:{"clearable":"","flat":"","hide-details":"","placeholder":"Nhập serial cần kiểm","prepend-inner-icon":"mdi-magnify","solo":"","disabled":_vm.page === 'detail'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openModalCheckingSerial.apply(null, arguments)}}})],1)]),_c('v-row',{staticClass:"white rounded-lg mx-0 pa-3"},[(
          _vm.inventoryRequestStatus.value &&
            _vm.inventoryRequestStatus.value.slice(0, 7) === 'loading'
        )?_c('v-col',{staticClass:"fixed-height",attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"color":"light-blue","buffer-value":"0","stream":""}})],1):[_c('v-col',{staticClass:"fixed-height",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-h6 font-weight-bold text-center mb-4"},[_vm._v(" Chờ kiểm ("+_vm._s(_vm.waitingCount)+"/"+_vm._s(_vm.total)+") ")]),_c('div',{staticClass:"scrollable"},_vm._l((_vm.inventory.waiting),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-medium serial-item mr-3 my-1",attrs:{"color":"grey lighten-4","label":""},on:{"click":function($event){return _vm.copyToClipboard(item.serial_number)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.serial_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])}),1)]),_c('v-col',{staticClass:"fixed-height",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-h6 font-weight-bold text-center mb-4"},[_vm._v(" Đúng thông tin ("+_vm._s(_vm.rightCount)+"/"+_vm._s(_vm.total)+") ")]),_c('div',{staticClass:"scrollable"},_vm._l((_vm.inventory.right),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-medium serial-item mr-3 my-1",attrs:{"color":"green lighten-4","label":""},on:{"click":function($event){return _vm.copyToClipboard(item.serial_number)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.serial_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])}),1)]),_c('v-col',{staticClass:"fixed-height",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-h6 font-weight-bold text-center mb-4"},[_vm._v(" Sai thông tin ("+_vm._s(_vm.wrongCount)+"/"+_vm._s(_vm.total)+") ")]),_c('div',{staticClass:"scrollable"},_vm._l((_vm.inventory.wrong),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-medium serial-item mr-3 my-1",attrs:{"color":"red lighten-3","label":""},on:{"click":function($event){return _vm.copyToClipboard(item.serial_number)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.serial_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])}),1)])]],2),(_vm.inventory && _vm.inventory.participants)?_c('v-row',{staticClass:"white rounded-lg mx-0 pa-3 mt-4"},_vm._l((_vm.inventory.participants),function(participant,index){return _c('div',{key:("participant-" + index)},[_c('v-divider',{staticClass:"my-3"}),_c('div',[_c('div',{staticClass:"font-weight-bold d-flex align-center mb-2"},[_c('span',[_vm._v(_vm._s(_vm.getNameRole(participant.role)))])]),_c('v-autocomplete',{staticClass:"font-weight-medium text-body-1 price--text-right mt-0 pt-0",attrs:{"dense":"","flat":"","hide-details":"","items":_vm.employees,"item-text":"name","item-value":"id","no-data-text":"Không có dữ liệu","placeholder":"Kỹ thuật viên","disabled":""},model:{value:(participant.user_id),callback:function ($$v) {_vm.$set(participant, "user_id", $$v)},expression:"participant.user_id"}}),(participant.role === 'SHIPPING')?_c('v-text-field',{staticClass:"text-body-1 mt-4",attrs:{"clearable":"","dense":"","hide-details":"auto","outlined":"","placeholder":"Quãng đường (km)","disabled":""},model:{value:(participant.value),callback:function ($$v) {_vm.$set(participant, "value", $$v)},expression:"participant.value"}}):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('v-textarea',{staticClass:"text-body-1 mt-2",attrs:{"auto-grow":"","hide-details":"","outlined":"","rows":"3","single-line":"","placeholder":"Ghi chú","disabled":""},model:{value:(participant.note),callback:function ($$v) {_vm.$set(participant, "note", $$v)},expression:"participant.note"}})],1)],1)}),0):_vm._e()],1),(_vm.page === 'add')?_c('modal-checking-serial'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }